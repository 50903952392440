// eslint-disable-next-line import/no-anonymous-default-export
import { clientId } from "./constants/okta";
export const oktaconfig = {
    oidc: {
      issuer: process.env.REACT_APP_OKTA_ISSUER,
      clientId: clientId,
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      redirectUri: `${window.location.origin}/implicit/callback`,
      pkce: true,
      services:{
        autoRenew: true,
        expireEarlySeconds: 5 * 60
      },
      tokenManager:{
        autoRenew: true,
        expireEarlySeconds: 5 * 60
      }
    },
    widget: {
      baseUrl: 'https://myarc.oktapreview.com/',
      clientId: clientId,
      redirectUri: `${window.location.origin}/implicit/callback`,
      logo:  'https://www2.arccorp.com/globalassets/arc-logos/corporate-logos/arc-logo-l-teal.png',
      authParams: {
        pkce: true,
        issuer: process.env.REACT_APP_OKTA_ISSUER,
        scopes: ['openid', 'profile', 'email', 'offline_access'],
        services:{
          autoRenew: true,
          expireEarlySeconds: 5 * 60
        },
        tokenManager:{
          autoRenew: true,
          expireEarlySeconds: 5 * 60
        }
      }
    }
  };